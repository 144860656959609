import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/maui/DropBottom.svg';
import DropUp from '../../images/case_studies/maui/DropUp.svg';
import BKGImg from "../../images/case_studies/maui/banner-maui.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import {useInView} from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import SEO from "../../components/SEO";
import Result3 from '../../images/case_studies/maui/Dati Maui.json';
import Result2 from '../../images/case_studies/maui/Maui Carrello.json';
import Result1 from '../../components/UI/Lottie/Colleghi.json';
import idibLogo from '../../images/case_studies/maui/logoidib.jpg';
import {Fade} from 'react-reveal';
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatsDiv = styled.div`
margin-top: 32px;
h3{
color:#000000;
font-weight:700;
font-style:normal;
font-size:32px;
margin:0px;
}
p{
    color:#118667;
    font-size:18px;
    font-weight:700;
}
`

const CompanyStats = () => {
    return <StatsDiv>
        <h3>3.200 +</h3>
        <p style={{color: '#1EBA91'}}>
            <FormattedMessage id={'maui.stats_1_msg'}/>
        </p>
    </StatsDiv>
}

const MauiCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView2, inViewG2] = useInView();
    const [graphView3, inViewG3] = useInView();
    const keywords = intl.formatMessage({id: 'audi.meta_keywords'}).split(',') || [];

    return (
        <>
            <SEO title={intl.formatMessage({id: 'maui.meta_title'})}
                 description={intl.formatMessage({id: 'maui.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>

            <HeroCaseStudy hasPartner={true} partnerLogo={idibLogo} heroImage={'case_studies/maui/Header-maui-.png'} title={'maui.title'}
                           stats={<CompanyStats/>} subtitle1={'maui.subtitle'}
                           color={'linear-gradient(90deg, #1EBA91 0%, #118667 100%)'} intl={intl}
                           categories={['portfolio.filters.design_dev', 'portfolio.filters.data_analysis_consulting']}/>
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block"/>
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'maui.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-md-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"maui.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"maui.client_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"maui.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"maui.client_needs_1"}/>
                                </Paragraph>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"maui.client_needs_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={1920} imageHeight={700}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block"/>
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block"/>
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle number={'02'} text={'maui.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"maui.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id="maui.services_txt_1"/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'03'} text={'maui.workflow_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row ">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"maui.solutions_big_title"}/>
                                </Heading>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"maui.solutions_title_1"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"maui.solutions_txt_1"}/>
                                </Paragraph>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"maui.solutions_title_2"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"maui.solutions_txt_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-4 col-sm-12 offset-0 offset-md-1 position-relative">
                                <Image filename={'case_studies/maui/Body-maui-goccia-1.jpg'} alt={'body Maui'}
                                       className={'case-study-right-pic'}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade bottom delay={400}>
                            <div className="col-md-6 col-sm-12 order-last order-md-first d-md-flex d-none">
                                <Image filename={'case_studies/maui/Body-maui-goccia-2.jpg'} imgClassName="section-image" alt={'Body Maui'}/>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-12 col-md-5 offset-md-1 order-first order-md-last">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"maui.solutions_title_3"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"maui.solutions_txt_3"}/>
                                </Paragraph>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"maui.solutions_title_4"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"maui.solutions_txt_4"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Results
                subtitle="maui.dark_section_paragraph"
                testimonialQuote="maui.dark_section_quote"
                testimonialImg="vincenzo_ruffa.jpg"
                testimonialName="maui.dark_section_testimonial_name"
                testimonialRole="maui.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Result1}
                            inView={inViewG1}
                            height="96px"
                            width="120px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={8000}
                            prefix="+"
                            headingStyle={{fontWeight: 500}}
                            label="maui.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Result2}
                            inView={inViewG2}
                            height="98px"
                            width="208px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            prefix="+"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={4700}
                            label="maui.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView3}>
                        <LottieController
                            lottieJSON={Result3}
                            inView={inViewG3}
                            height="98px"
                            width="222px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            prefix="+"
                            decimals={1}
                            inView={inViewG3}
                            valueFrom={0}
                            valueTo={1.2}
                            measureUnit="M"
                            label="maui.stats_3"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="maui.next_success_case_title"
                desc="maui.next_success_case_description"
                link="/case-study/bancaAnagni/"
                intl={intl}
            />
        </>
    );
};

const customProps = {
    localeKey: "maui", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(MauiCaseStudy));
